<template>
  <div>
    <v-toolbar>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <v-select label="Mois" v-model="selected_month" :items="months" item-text="name"
                    item-value="value"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-select label="Sites"
                    :items="sites_select"
                    item-text="name"
                    item-value="value"
                    v-model="selected_site"
          ></v-select>
        </v-slide-item>
        <v-slide-item>
          <v-select label="Année"
                    :items="active_years"
                    v-model="selected_year"
                    item-value="value"
                    item-text="name">
          </v-select>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>

    </v-toolbar>
    <v-card id="stat-tickets">
      <v-row v-if="loaded">
        <v-col>
          <apexchart type="bar" height="350" :options="count_tickets_by_state_graph.chartOptions"
                     :series="count_tickets_by_state_graph.series"></apexchart>
        </v-col>
      </v-row>
      <v-row v-else align-content="center" justify="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import {STATUS_LABELS} from "@/constants/tickets";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";

export default {
  name: 'StatTickets',
  components: {"apexchart": VueApexCharts},
  async mounted() {
    await this.$store.dispatch("tickets/fetchTickets");
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
    this.loaded = true;
  },
  data() {
    return {loaded: false};
  },
  computed: {
    count_tickets_by_state() {
      return this.$store.getters["tickets/stats/count_tickets_by_state"];
    },
    selected_year: {
      get() {
        return this.$store.getters["tickets/stats/getSelectedYear"];
      },
      set(value) {
        return this.$store.commit("tickets/stats/setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.getters["tickets/stats/getSelectedMonth"];
      },
      set(value) {
        return this.$store.commit("tickets/stats/setSelectedMonth", value);
      }
    },
    selected_site: {
      get() {
        return this.$store.getters["tickets/stats/getSelectedSite"];
      },
      set(value) {
        return this.$store.commit("tickets/stats/setSelectedSite", value)
      }
    },
    sites_select() {
      return [
        {value: null, name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    count_tickets_by_state_graph() {
      return {
        series: [{
          name: "Nombre de tickets",
          data: this.ticketByStateData
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
          },
          title: {
            text: "Répartition actuelle des tickets",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.ticketByStateLabels,
          },
        }
      }
    },
    ticketByStateData() {
      return Object.values(this.count_tickets_by_state);
    },
    ticketByStateLabels() {
      return Object.keys(this.count_tickets_by_state).map(state => STATUS_LABELS[state])
    },
    months() {
      return [
        {"value": null, "name": "Tous les mois"},
        {"value": 0, "name": "Janvier"},
        {"value": 1, "name": "Février"},
        {"value": 2, "name": "Mars"},
        {"value": 3, "name": "Avril"},
        {"value": 4, "name": "Mai"},
        {"value": 5, "name": "Juin"},
        {"value": 6, "name": "Juillet"},
        {"value": 7, "name": "Août"},
        {"value": 8, "name": "Septembre"},
        {"value": 9, "name": "Octobre"},
        {"value": 10, "name": "Novembre"},
        {"value": 11, "name": "Décembre"},];
    },
    active_years() {
      return this.$store.getters["tickets/stats/active_years"];
    }
  },
  methods: {
    site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    async printStats() {
      let statNode = document.querySelector("#stat-tickets")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`financier${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    }
  }
}
</script>