<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>
        Contrôles règlementaires
      </v-toolbar-title>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <v-btn color="primary" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card v-if="loaded" id="stat-controles">
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="controles">
            <template v-slot:item.name="{item}">
              <router-link :to="'/contracts/' + item.id">{{item.name}}</router-link>
            </template>
            <template v-slot:item.date_start="{item}">
              {{getFormattedDate(item.date_end)}}
            </template>
            <template v-slot:item.date_end="{item}">
              {{getFormattedDate(item.date_end)}}
            </template>
            <template v-slot:item.periodicite="{item}">
              {{ item.periodicite }}
            </template>
            <template v-slot:item.remaining_time="{item}">
              <span v-if="item.remaining_time > 7" class="green--text font-weight-bold">{{item.remaining_time}} jours</span>
              <span v-else-if="item.remaining_time < 7 && item.remaining_time >= 2" class="orange--text font-weight-bold">{{item.remaining_time}} jours</span>
              <span v-else-if="item.remaining_time === 0" class="red--text font-weight-bold">Aujourd'hui</span>
              <span v-else-if="item.remaining_time === 1" class="red--text font-weight-bold">Demain</span>
              <span v-else-if="item.remaining_time < 0" class="red--text font-weight-bold">Contrôle dépassé</span>
            </template>
            <template v-slot:item.attachment="{item}">
              <a class="text-decoration-none" target="_blank" v-if="item.attachment !== null" :href="item.attachment"><v-icon>mdi-link</v-icon></a>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";

export default {
  name: 'TabControles',
  mixins: [DateUtilMixin],
  async mounted() {
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      headers: [
        {"value": "name",  "text": "Contrat"},
        {"value": "date_start",  "text": "Date du dernier contrôle"},
        {"value": "date_end",  "text": "Date de prochain contrôle"},
        {"value": "periodicite",  "text": "Périodicité"},
        {"value": "nbre_assets",  "text": "Nombre d'équipements concernés"},
        {"value": "prestataire_name",  "text": "Prestataire"},
        {"value": "remaining_time",  "text": "Contrôle dans"},
        {"value": "attachment",  "text": "Pièce jointe"},
      ]
    }
  },
  computed: {
    controles() {
      return this.$store.getters["contracts/stats/controles_reglementaires"];
    }
  },
  methods: {
    async printStats() {
      let statNode = document.querySelector("#stat-controles")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`controles${dayjs().format("DD-MM-YYYYTHH_MM")}.pdf`);
    }
  }
}
</script>