<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>
        Financier général
      </v-tab>
      <v-tab>
        Prestataires
      </v-tab>
      <v-tab>
        Contrats
      </v-tab>
      <v-tab>
        Tickets
      </v-tab>
      <v-tab>
        Interventions
      </v-tab>
      <v-tab>
        Équipements
      </v-tab>
      <v-tab>
        Contrôles règlementaires
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <StatFinancier />
      </v-tab-item>
      <v-tab-item>
        <StatPrestataires/>
      </v-tab-item>
      <v-tab-item>
        <StatContrats/>
      </v-tab-item>
      <v-tab-item>
        <StatTickets/>
      </v-tab-item>
      <v-tab-item>
        <StatInterventions/>
      </v-tab-item>
      <v-tab-item>
        <TabEquipements/>
      </v-tab-item>
      <v-tab-item>
        <TabControles/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import StatFinancier from "@/components/statistiques/StatFinancier";
import StatTickets from "@/components/statistiques/StatTickets";
import StatContrats from "@/components/statistiques/StatContrats";
import StatPrestataires from "@/components/statistiques/StatPrestataires";
import TabEquipements from "@/components/statistiques/TabEquipements";
import TabControles from "@/components/statistiques/TabControles";
import StatInterventions from "@/components/statistiques/StatInterventions";

export default {
  name: "Statistiques",
  components: {
    StatInterventions,
    TabControles, TabEquipements, StatPrestataires, StatContrats, StatTickets, StatFinancier},
  data() {
    return {tab: null}
  },
  methods: {
    name() {

    }
  },
}
</script>