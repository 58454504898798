<template>
  <div v-if="loaded">
    <v-toolbar>
      <v-slide-group>
        <v-slide-item>
          <v-select @change="reloadStats" label="Contrat" class="mx-2" :items="contracts" v-model="filters.contract" item-text="name" item-value="id"></v-select>
        </v-slide-item>
        <v-slide-item>
          <v-select @change="reloadStats" :items="active_years" v-model="filters.year"></v-select>
        </v-slide-item>
        <v-slide-item>
          <v-checkbox @change="reloadStats" label="Afficher le montant cumulé" v-model="filters.cumul"></v-checkbox>
        </v-slide-item>
        <v-slide-item>
          <v-checkbox @change="reloadStats" label="Afficher la prédiction de l'année" v-model="filters.predict"></v-checkbox>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card id="stat-contrats">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="3"><h2 class="text-center primary--text">{{ filters.year || "" }}</h2></v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="3">
          <h2 class="text-center primary--text">
            <a :href="
            (stat_contrats.contract_id !== null) ?
            '/finance?year=' + this.filters.year + '&contract=' + this.filters.contract
            : ''">{{ stat_contrats.contract_name }}</a>
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center" justify-lg="space-around">
        <v-col cols="12" lg="5">
          <v-card class="white--text text-center display-1 pa-4" color="primary">
            <span>Total prévu du contrat <br> {{ sous_contrat_realise_par_contrat}} € HT</span>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card class="text-center white--text display-1 pa-4" color="#D9B300">
            <span>Dépenses supplémentaires réalisés <br> {{ supplementaire_realise_par_contrat }} € HT</span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <apexchart type="bar" height="350"
                     :series="realise_mensuel_par_contrat_graph.series"
                     :options="realise_mensuel_par_contrat_graph.chartOptions"></apexchart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import * as dayjs from "dayjs";

export default {
  name: 'StatContrats',
  components: {"apexchart": VueApexCharts},
  data() {
    return {
      loaded: false,
      stat_contrats: null,
      filters: {
        year: dayjs().year(),
        contract: null,
        cumul: false,
        predict: false,
      },
    }
  },
  async mounted() {
    if(this.$store.getters["contracts/allContracts"].length === 0){
      await this.$store.dispatch("contracts/fetchContracts");
    }
    this.stat_contrats = await this.$store.dispatch("contracts/getContractStats", this.filters);
    this.loaded = true;
  },
  computed: {
    sous_contrat_realise_par_contrat() {
      if (this.stat_contrats !== null) {
        if(this.filters.cumul === false){
          return this.stat_contrats.depense_sous_contrat.reduce((a, b) => a + b, 0)
              .toLocaleString(undefined, {minimumFractionDigits: 2});
        } else {
          return Math.max(...this.stat_contrats.depense_sous_contrat);
        }
      } else {
        return 0.00;
      }
    },
    supplementaire_realise_par_contrat() {
      if (this.stat_contrats !== null) {
        if (this.filters.cumul === false) {
          return this.stat_contrats.depense_sous_contrat_supplementaire.reduce((a, b) => a + b, 0)
              .toLocaleString(undefined, {minimumFractionDigits: 2});
        } else {
          return Math.max(...this.stat_contrats.depense_sous_contrat_supplementaire);
        }
      } else {
        return 0.00;
      }
    },
    active_years() {
      return this.stat_contrats !== null ? this.stat_contrats.active_years : [dayjs().year()];
    },
    contracts() {
      return [{id: null, name: "Tout les contrats"}, ...this.$store.getters["contracts/allContracts"]];
    },
    realise_mensuel_par_contrat_graph() {
      return {
        series: [{
          name: 'Sous contrat',
          data: this.stat_contrats?.depense_sous_contrat || [],
        }, {
          name: 'Sous contrat supplémentaire',
          data: this.stat_contrats.depense_sous_contrat_supplementaire || [],
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
          },
          yaxis: {
            title: {
              text: 'Réalisé'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €";
              }
            }
          }
        },
      }
    },
  },
  methods: {
    async printStats() {
      let statNode = document.querySelector("#stat-contrats")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`contrats${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    },
    async reloadStats() {
      this.loaded = false;
      this.stat_contrats = await this.$store.dispatch("contracts/getContractStats", this.filters);
      this.loaded = true;
    }
  }
}
</script>