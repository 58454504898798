var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(" Contrôles règlementaires ")]),_c('v-slide-group',[_c('v-slide-item',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.printStats}},[_vm._v("Imprimer")])],1)],1)],1),(_vm.loaded)?_c('v-card',{attrs:{"id":"stat-controles"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.controles},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/contracts/' + item.id}},[_vm._v(_vm._s(item.name))])]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_end))+" ")]}},{key:"item.date_end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_end))+" ")]}},{key:"item.periodicite",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.periodicite)+" ")]}},{key:"item.remaining_time",fn:function(ref){
var item = ref.item;
return [(item.remaining_time > 7)?_c('span',{staticClass:"green--text font-weight-bold"},[_vm._v(_vm._s(item.remaining_time)+" jours")]):(item.remaining_time < 7 && item.remaining_time >= 2)?_c('span',{staticClass:"orange--text font-weight-bold"},[_vm._v(_vm._s(item.remaining_time)+" jours")]):(item.remaining_time === 0)?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v("Aujourd'hui")]):(item.remaining_time === 1)?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v("Demain")]):(item.remaining_time < 0)?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v("Contrôle dépassé")]):_vm._e()]}},{key:"item.attachment",fn:function(ref){
var item = ref.item;
return [(item.attachment !== null)?_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":item.attachment}},[_c('v-icon',[_vm._v("mdi-link")])],1):_vm._e()]}}],null,false,1941618957)})],1)],1)],1):_c('v-card',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }