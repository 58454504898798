<template>
  <div class="white">
    <v-toolbar>
      <v-slide-group class="mt-2">
        <v-slide-item class="mx-2">
          <v-select @change="reloadStats" label="Mois" v-model="filters.month" :items="months" item-text="name"
                    item-value="value"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-select @change="reloadStats" label="Année" v-model="filters.year" item-text="name" item-value="value"
                    :items="active_years"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <site-tree-selector @change="reloadStats" nomargin v-model="filters.sites"/>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-checkbox @change="reloadStats" label="Activer la prédiction des mensualités" v-model="filters.predict"></v-checkbox>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>

    <v-card v-if="loaded" class="pa-2 white" id="stat-financier">
      <v-row>
        <v-col>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <v-card
                  @click="$router.push('/finance?statut=interventions&sites=' + selected_sites_joined + '&year=' + filters.year + '&month=' + filters.month)"
                  color="orange" class="pa-2 text-center">
            <span class="white--text display-1">
              Total dépensé en interventions<br>
              {{ total_interventions }} €
            </span>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                  @click="$router.push('/finance?statut=contrats&sites=' + selected_sites_joined + '&year=' + filters.year + '&month=' + filters.month)"
                  color="purple" class="pa-2 text-center">
            <span class="white--text display-1">
              Total dépensé en contrats<br>
              {{ total_contrats }} €
            </span>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col>
              <v-card
                  @click="$router.push('/finance?statut=previsionnel&sites=' + selected_sites_joined + '&year=' + filters.year + '&month=' + filters.month)"
                  color="cyan" class="white--text text-center pa-2">
                <span class="display-1">Budget maintenance<br>{{ total_previsionnel_per_year }} € HT</span>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                  @click="$router.push('/finance?statut=realise&sites=' + selected_sites_joined + '&year=' + filters.year + '&month=' + filters.month)"
                  color="#D9B300" class="white--text text-center pa-2">
                <span class="display-1">Total réalisé<br>{{ total_realise_per_year }} € HT</span>
              </v-card>
            </v-col>
            <v-col>
              <v-card color="green" class="white--text text-center pa-2">
                <span class="display-1">Écart<br>{{ ecart_per_year }} € HT</span>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <apexchart type="bar" height="350" :options="realise_par_type.chartOptions"
                     :series="realise_par_type.series"></apexchart>
        </v-col>
        <v-col>
          <apexchart type="pie" height="350" :options="realise_par_equipement_graph.chartOptions"
                     :series="realise_par_equipement_graph.series"></apexchart>
        </v-col>
        <v-col>
          <apexchart type="pie" height="350"
                     :options="realise_par_contrat_graph.chartOptions"
                     :series="realise_par_contrat_graph.series"></apexchart>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";


export default {
  name: 'StatFinancier',
  components: {SiteTreeSelector, "apexchart": VueApexCharts},
  mixins: [DateUtilMixin],
  async mounted() {
    await this.$store.dispatch("sites/fetchSites");
    this.stats_financiers = await this.$store.dispatch("finance/getFinancialStats", this.filters);
    this.loaded = true;
  },
  methods: {
    async printStats() {
      let statNode = document.querySelector("#stat-financier")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`financier${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    },
    async reloadStats() {
      this.loaded = false;
      this.stats_financiers = await this.$store.dispatch("finance/getFinancialStats", this.filters);
      this.loaded = true;
    },
    site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    showEntriesByType(event, context, config) {
      let typeDepenseIndex = config.dataPointIndex;
      if (typeDepenseIndex !== -1) {
        this.$router.push(`/finance/?&sites=` + this.selected_sites_joined + `&year=` + this.filters.year + `&month=` + this.filters.month + `&type=${this.typesDepenseList[typeDepenseIndex]}`)
      }
    },
    showEntriesByContract(event, context, config) {
      let contractIndex = config.dataPointIndex;
      if (contractIndex !== -1) {
        this.$router.push(`/finance/?&type=` + encodeURIComponent("Dépense sous contrat") + `&sites=` + this.selected_sites_joined + `&year=` + this.filters.year + `&month=` + this.filters.month +
            `&contract_name=${this.stats_financiers?.depense_par_contrat.labels[contractIndex]}`)
      }
    }
  },
  data: function () {
    return {
      loaded: false,
      filters: {
        year: dayjs().year(),
        sites: [],
        month: null,
        predict: null,
      },
      stats_financiers: null,
      typesDepenseList: ["Dépense ponctuelle", "Dépense sous contrat", "Dépense sous contrat supplémentaire", "Casse"],
    }
  },
  computed: {
    total_realise_per_year() {
      return this.stats_financiers?.total_realise;
    },
    total_previsionnel_per_year() {
      return this.stats_financiers?.total_previsionnel;
    },
    ecart_per_year() {
      return this.stats_financiers?.ecart;
    },
    total_interventions() {
      return this.stats_financiers?.total_interventions?.toFixed(2);
    },
    total_contrats() {
      return this.stats_financiers?.total_contrats?.toFixed(2);
    },

    realise_par_type() {
      return {
        series: [{
          name: "Réalisé",
          data: this.stats_financiers?.realise_par_type.values,
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            events: {
              click: this.showEntriesByType
            }
          },
          title: {
            text: "Réalisé par type de dépense",
            align: "center",
          },
          colors: ["#208774", "#E7B05C", "#706f6f"],
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.stats_financiers?.realise_par_type.labels,
          }
        }
      }
    },
    realise_par_equipement_graph() {
      return {
        series: this.stats_financiers?.depense_par_asset.values,
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350
          },
          title: {
            text: "Réalisé par équipement",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: this.stats_financiers?.depense_par_asset.labels,
        }
      }
    },
    realise_par_contrat_graph() {
      return {
        series: this.stats_financiers?.depense_par_contrat.values,
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350,
            events: {
              dataPointSelection: this.showEntriesByContract
            }
          },
          title: {
            text: "Réalisé par contrat",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: this.stats_financiers?.depense_par_contrat.labels,
        }
      }
    },
    assets() {
      return this.$store.getters["assets/allAssets"];
    },
    contracts() {
      return this.$store.getters["contracts/allContracts"];
    },
    selected_year: {
      get() {
        return this.$store.getters["finance/stats/getSelectedYear"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.getters["finance/stats/getSelectedMonth"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedMonth", value);
      }
    },
    selected_site_tree: {
      get() {
        return this.$store.getters["finance/stats/getSelectedSiteTree"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedSiteTree", value);
      }
    },
    sites() {
      return [
        {value: null, name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    active_years() {
      let active_years = []
      if (this.stats_financiers !== null) {
        for (const year of this.stats_financiers.active_years) {
          active_years.push({value: year, name: year});
        }
      }
      return active_years;
    },
    selected_sites_joined() {
      return this.filters.sites.join(",");
    }
  },
}
</script>