export const StatHelperMixin = {
    methods: {
        roundToInt(value) {
            return Math.round(value).toString();
        },
        roundToDecimal(value) {
            return (Math.round(value * 100) / 100).toString();
        },
        positive(value) {
            return value < 0 ? 0 : value;
        }
    }
}