<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>Équipements</v-toolbar-title>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <SiteTreeSelector @change="reloadStats" v-model="filter.sites" nomargin/>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card v-if="loaded" id="stat-equipements">
      <v-row>
        <v-col cols="6">
          <apexchart type="pie" height="350"
                     :options="realise_par_categorie_graph.chartOptions"
                     :series="realise_par_categorie_graph.series"></apexchart>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="stats_equipement.tableau_assets">
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: 'TabEquipements',
   components: {SiteTreeSelector, "apexchart": VueApexCharts},
  async mounted() {
    this.stats_equipement = await this.$store.dispatch("assets/fetchAssetsStats", this.filter);
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      stats_equipement: null,
      filter: {
        sites: [],
      },
      headers: [
        {"value": "name", "text": "Équipement"},
          {"value": "domaine", "text": "Domaine"},
        {"value": "nbre_interventions", "text": "Nombre d'interventions"},
        {"value": "total_depense", "text": "Total des dépenses"},
        {"value": "cout_achat", "text": "Coût d'achat"},
        {"value": "valeur_comptable", "text": "Valeur comptable"},
        {"value": "solde_amortissement", "text": "Solde amortissement"},
      ]
    }
  },
  computed: {
    realise_par_categorie_graph() {
      return {
        series: this.stats_equipement?.depense_par_domaine.values || [],
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350
          },
          title: {
            text: "Réalisé par domaine",
            align: "center",
          },
          tooltip: {
              y: {
                formatter: function (val) {
                  return val + " €"
                }
              }
            },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: this.stats_equipement?.depense_par_domaine.labels || [],
        }
      }
    },

  },
  methods: {
    async reloadStats() {
      this.loaded = false;
      this.stats_equipement = await this.$store.dispatch("assets/fetchAssetsStats", this.filter);
      this.loaded = true;
    },
    getAssetName(asset_id) {
      let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
      return asset !== null ? asset?.name : "Équipement inconnu";
    },
    getAssetCost(asset_id) {
      let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
      return asset !== null && asset?.cout_achat != null ? asset.cout_achat : 0;
    },
    getAsset(asset_id) {
      return this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
    },
    getAssetCategory(asset_id) {
      let asset = this.getAsset(asset_id);
      return asset?.category?.name || "Sans catégorie";
    },
    getAssetValue(asset_id) {
     let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
     if (asset?.cout_achat !== null && asset?.date_achat !== null && asset?.amortissement !== null) {
       let cout_annuel = parseFloat(asset.cout_achat) / parseInt(asset.amortissement);
       let annees_ecoules = dayjs().year() - dayjs(asset.date_achat).year() + 1;
       let valeur_comptable = parseFloat(asset.cout_achat) - (annees_ecoules * cout_annuel);
       valeur_comptable = valeur_comptable < 0 ? 0 : valeur_comptable;
       return Math.round(valeur_comptable * 100) / 100;
     } else {
       return 0;
     }
    },
    getAssetSolde(asset_id) {
      return this.getAssetCost(asset_id) - this.getAssetValue(asset_id);
    },
    async printStats() {
      let statNode = document.querySelector("#stat-equipements")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`equipements${dayjs().format("DD-MM-YYYYTHH_MM")}.pdf`);
    }
  }
}
</script>