<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>Prestataires</v-toolbar-title>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card v-if="loaded" id="stat-prestataires">
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="prestataire_stats">
            <template v-slot:item.fullname="{ item }">
              <h4 class="primary--text">
                <span v-if="item.prestataire_id === null">{{ item.fullname }}</span>
                <a :href="'/finance?prestataire=' + item.prestataire_id" v-else>{{ item.fullname }}</a>
              </h4>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";
import {StatHelperMixin} from "@/mixins/stats/StatHelperMixin";

export default {
  name: 'StatPrestataires',
  mixins: [StatHelperMixin],
  async mounted() {
    this.prestataire_stats = await this.$store.dispatch("profile/fetchPrestataireStats");
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      headers: [
        {value: "fullname", text: "Intervenant"},
          {value: "group", text: "Groupe"},
        {value: "note_globale", text: "Note globale"},
        {value: "delai_reponse_moyen", text: "Délai de réponse moyen"},
          {value: "delai_realisation_moyen", text: "Délai de réalisation moyen"},
        {value: "ca_total", text: "C.A total"},
        {value: "nbre_interventions", text: "Nombre d'interventions réalisés"},
      ],
      prestataire_stats: [],
    }
  },
  computed: {
    interventions_stats() {
      return this.$store.getters["interventions/stats/interventions_per_prestataire"];
    }
  },
  methods: {
    getIntervenantName(intervenant_id) {
      let user = this.$store.getters["profile/profiles"].find(user => user.id === intervenant_id);
      return user !== null ? user.fullname : "Utilisateur inconnu";
    },
    getIntervenantGroup(intervenant_id) {
      let user = this.$store.getters["profile/profiles"].find(user => user.id === intervenant_id);
      return user !== null ? user.group : "Groupe inconnu";
    },
    format_note(note) {
      return Math.round(note * 100) / 100;
    },
    async printStats() {
      let statNode = document.querySelector("#stat-prestataires")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`prestataires${dayjs().format("DD-MM-YYYYTHH_MM")}.pdf`);
    }
  }
}
</script>